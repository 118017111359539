<template>
    <div>
        <HeaderCard tittle="Clientes em cidades sem prestador" :loading="loading" :comFiltro="false" />
        <div class="mt-2">
            <DataTable
                :rows="10"
                :totalRecords="total"
                :value="dados"
                class="h-26rem"
                currentPageReportTemplate="Página {currentPage} de {totalPages}"
                lazy
                paginator
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                scroll-height="100%"
                scrollable
                @page="onPage"
                @sort="onSort"
            >
                <template #empty> Nenhum registro encontrado. </template>
                <Column field="cidade" header="Cidades" sortable style="width: 25%"></Column>
                <Column field="estado" header="Estado" sortable style="width: 5%"></Column>
                <ProgressBar v-if="loading" class="w-full border-noround z-2 absolute" mode="indeterminate" style="height: 4px; top: 40px" />
            </DataTable>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';

export default {
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            page: 1,
            total: 0,
            perPage: 10,
            sortField: null,
            sortOrder: null,
            loading: false
        };
    },
    components: {
        HeaderCard
    },
    mounted() {
        this.queryData();
    },
    methods: {
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes/cidades-sem-prestador', {
                    params: {
                        filtros: JSON.stringify({
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                        }),
                        paginacao: JSON.stringify({
                            limit: this.perPage,
                            page: this.page,
                            sortField: this.sortField,
                            sortOrder: this.sortOrder
                        })
                    }
                });
                this.dados = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.loading = false;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
                this.loading = false;
            }
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.perPage = event.rows;
            await this.queryData();
        },
        async onSort(event) {
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            await this.queryData();
        }
    }
};
</script>
<style scoped>
:deep(::-webkit-scrollbar-track) {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar) {
    width: 6px;
    height: 6px;
    background: #f4f4f4;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}
</style>
