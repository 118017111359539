<template>
    <div>
        <HeaderCard :loading="loading" tittle="Agendamentos concluídos" :filtroStatusConcluidos="true" @onLoad="load" />
        <Chart type="line" :data="stackedData" :options="stackedOptions" class="h-28rem" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import { uniq } from 'ramda';
import HeaderCard from '../../components/HeaderCard.vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import moment from 'moment-timezone';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        usersIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            statusIds: [],
            loading: false,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 25;
                        };
                    }
                }
            ]
        };
    },
    computed: {
        stackedData() {
            const labels = uniq(this.dados.map((item) => this.formatarData(item.dataCriacao)));
            const status = uniq(this.dados.map((item) => ({ label: item.status, cor: item.cor || '#EF2595' })));
            const totalDataset = {
                label: 'Total',
                data: this.getQuantidadeTotal(labels),
                backgroundColor: '#EF2595',
                borderColor: '#EF2595',
                tension: 0.4
            };

            return {
                labels,
                datasets: [
                    ...status.map((status) => {
                        return {
                            label: status.label,
                            data: this.buscarQuantidadePorLabelDoStatus(status.label, labels),
                            backgroundColor: status.cor,
                            borderColor: status.cor,
                            tension: 0.4
                        };
                    }),
                    totalDataset
                ]
            };
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                pointBackgroundColor: '#fff',
                pointRadius: 4,
                borderWidth: 1.63,
                aspectRatio: 0.6,
                plugins: {
                    legend: {
                        labels: {
                            boxHeight: 10,
                            fontColor: '#495057',
                            font: {
                                weight: 500
                            }
                        }
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        backgroundColor: '#495057',
                        borderRadius: 2,
                        color: 'white',
                        padding: 6
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            };
        }
    },
    methods: {
        buscarQuantidadePorLabelDoStatus(status, labels) {
            const quantidadesPorLabelDoStatus = [];
            for (const label of labels) {
                const item = this.dados.find((item) => {
                    return this.formatarData(item.dataCriacao) === label && item.status === status;
                });
                quantidadesPorLabelDoStatus.push(item?.quantidade?.toLocaleString('pt-BR') || '0');
            }

            return quantidadesPorLabelDoStatus;
        },
        getQuantidadeTotal(labels) {
            const totalQuantidades = [];

            for (const label of labels) {
                const totalQuantidade = this.dados
                    .filter((item) => {
                        return this.formatarData(item.dataCriacao) === label;
                    })
                    .reduce((total, item) => total + parseInt(item.quantidade), 0);
                totalQuantidades.push(totalQuantidade || 0);
            }
            return totalQuantidades;
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            this.statusIds = params.statusIds;
            this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined,
                            statusIds: this.statusIds?.length ? this.statusIds : undefined,
                            userIds: this.usersIds?.length ? this.usersIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        formatarData(data) {
            let formato;
            if (this.periodicidade === 'day') formato = 'DD/MM/YY';
            if (this.periodicidade === 'month') formato = 'MMM/YY';
            if (this.periodicidade === 'year') formato = 'YYYY';

            const dataFormatada = moment.tz(data, 'UTC').locale('pt-br').format(formato);
            return dataFormatada.replace(/^./, dataFormatada[0].toUpperCase());
        }
    }
};
</script>
