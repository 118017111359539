<template>
    <div>
        <HeaderCard :loading="loading" tittle="Quantidade de agendamentos por prestadores" @onLoad="load" />
        <div class="mt-2">
            <DataTable
                :pt="{
                    wrapper: { style: { height: 'calc(100% - 65px)' } }
                }"
                :rows="10"
                :totalRecords="total"
                :value="dados"
                class="h-30rem mt-4"
                currentPageReportTemplate="Página {currentPage} de {totalPages}"
                dataKey="id"
                lazy
                paginator
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                scroll-height="100%"
                scrollable
                @page="onPage"
                @sort="onSort"
            >
                <template #empty> Nenhum registro encontrado. </template>
                <Column field="nome" header="Prestador" sortable style="width: 25%" />
                <Column field="quantidade" header="Quantidade de procedimentos" sortable style="width: 25%" />
                <ProgressBar v-if="loading" class="w-full border-noround z-2 absolute" mode="indeterminate" style="height: 4px; top: 65px" />
            </DataTable>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            customerIdsArray: [],
            page: 1,
            total: 0,
            perPage: 10,
            sortField: null,
            sortOrder: null,
            loading: false
        };
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes/prestadores', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                        }),
                        paginacao: JSON.stringify({
                            limit: this.perPage,
                            page: this.page,
                            sortField: this.sortField,
                            sortOrder: this.sortOrder
                        })
                    }
                });

                this.dados = data.items;
                this.total = data.meta?.totalItems;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        onPage(event) {
            this.page = event.page + 1;
            this.perPage = event.rows;
            this.queryData();
        },
        onSort(event) {
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.queryData();
        }
    }
};
</script>
<style scoped>
:deep(::-webkit-scrollbar-track) {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar) {
    width: 6px;
    height: 6px;
    background: #f4f4f4;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}
</style>
